const links = {
  checkout: 'https://pay.hotmart.com/I84019647C?off=9kf47pqv&checkoutMode=10&utm_source=quiz'
}

const testimonials = [
  {
    author: 'Guilherme Nunes',
    quote:
      'Ebook se pagou logo no PRIMEIRO MES, com as técnicas COMPRAS INTELIGENTES comprei uma TV de 55`` e um Xiaomi MI 13 com 20% de desconto e ainda ganhei R$237,59 de comissão. Valeu cada centavo! RECOMENDO! 😊👍',
    stars: 5,
    image: '/testimonial-1.jpg'
  },
  {
    author: 'Maria Prado',
    quote:
      'Conheço o trabalho do Vinicius há um bom tempo, comprei o ebook com o intuito de fazer uma RENDA EXTRA e consegui! Hoje indico promoções para amigos e conhecidos. No último mês, fiz R$1.980,00 de comissão. 💸📈',
    stars: 5,
    image: '/testimonial-9.jpeg'
  },
  {
    author: 'Andriele Pereira',
    quote:
      'Quase sempre compro blusas, perfumes e shampoos na internet, depois que comprei o e-book compras inteligentes, consegui comprar os mesmos produtos MUITO mais barato e ainda recebo comissões das lojas, toda vez que eu compro! Gratidão ao ensinamento. 😍🛍️',
    stars: 5,
    image: '/testimonial-2.jpg'
  },
  {
    author: 'Alexandra Narancic',
    quote:
      'Aprendi a não cair mais em golpes na internet e consigo comprar roupas e materiais escolares aos meus filhos com desconto, inclusive semana passada comprei 2 pares de tênis com 50% de desconto. 👏👕👟',
    stars: 5,
    image: '/testimonial-3.jpg'
  },
  {
    author: 'Alessandro Cameron',
    quote:
      'Eu achava que sabia pesquisar preços e utilizar o Google como fonte de pesquisa, porém vi que estava enganado kkk. Obrigado COMPRAS INTELIGENTES! Nota 1000 pelas vídeos aulas! 🤩📚',
    stars: 5,
    image: '/testimonial-5.jpg'
  },
  {
    author: 'Gabriel Zanella',
    quote:
      'Sempre comprei muito no Mercado Livre e com este ebook aprendi a encontrar os menores preços e também ganhar comissões sobre as vendas! Quem estiver na dúvida, pode comprar! 👍🏼✅',
    stars: 5,
    image: '/testimonial-6.png'
  },
  {
    author: 'Juliana Pereira',
    quote:
      'Desde que adquiri o ebook "Compras Inteligentes", minha vida financeira mudou! Agora, indico promoções e alcancei R$2.100,00 em comissões! Essa ferramenta revolucionou minha forma de comprar e economizar. Recomendo a todos! 💰💸✨',
    stars: 5,
    image: '/testimonial-10.jpeg'
  },
  {
    author: 'Fábio Moreira',
    quote:
      'Comprei o ebook Compras Inteligentes quando estava mobiliando meu apartamento, economizei quase R$6.000,00 com as suas dicas que aprendi no curso. Recomendei para todos meus amigos. 👊🏻💰',
    stars: 5,
    image: '/testimonial-7.png'
  }
]

const benefits = {
  items: [
    'Economize de R$1000,00 a R$10.000,00 por ano!',
    'Consiga cupons exclusivos que ficam escondidos!',
    'Não seja mais manipulado pelo Google e comparadores de preços!',
    'Evite cair em golpes de compras online!'
  ],
  cta: 'Quero aproveitar esses benefícios!'
}

const surveyJson = {
  progressBarType: 'pages',
  showProgressBar: 'top',
  locale: 'pt-br',
  showQuestionNumbers: false,
  questionErrorLocation: 'bottom',
  goNextPageAutomatic: true,
  firstPageIsStarted: true,
  startSurveyText: 'QUERO APRENDER 🚀',
  pageNextText: 'Avançar',
  pages: [
    {
      name: 'FirstStep',
      elements: [
        {
          name: 'FirstStep',
          type: 'html',
          html: `
            <div class="sd-image-grid text-center flex flex-col gap-10">
              <img src="/background-video.jpeg" key="video.jpg" class="w-full h-full object-cover mt-[-20px]" />
              <h2 class="font-bold text-center">
                O <strong class="uppercase text-primary">Segredo</strong> para Economizar de 10% a 80% em Qualquer Produto e Ainda Ganhar uma <strong class="uppercase text-primary">Renda Extra</strong> com Compras Inteligentes!
              </h2>
              <p>Meu nome é Vinicius, e há 7 anos trabalho neste mercado, encontrando os menores preços na internet. Hoje, vou te revelar o <strong class="uppercase text-primary">SEGREDO</strong> para encontrar os melhores descontos online e garantir uma renda extra!</p>
            </div>
          `
        }
      ]
    },
    {
      name: 'PurchaseExperience',
      elements: [
        {
          name: 'PurchaseExperience',
          title:
            'Você compra ou já comprou algum produto na internet?',
          description: 'Você já comprou: tênis, tv, celular, notebook, roupas, ou algum outro produto alguma vez na internet?',
          descriptionLocation: 'underTitle',
          type: 'radiogroup',
          choices: [
            {
              value: true,
              text: 'Sim, adoro fazer compras online! 🚀'
            },
            {
              value: false,
              text: 'Ainda não, mas estou interessado em começar. 😁'
            }
          ]
        }
      ]
    },
    {
      name: 'ProductsBought',
      elements: [
        {
          name: 'ProductsBought',
          title: 'Em média, quantos produtos você comprou no último ano? 🛒',
          visibleIf: '{PurchaseExperience} == true',
          description:
            'Compartilhe conosco a quantidade de produtos que você adquiriu nos últimos 12 meses e descubra como você se destaca como um consumidor online!',
          type: 'radiogroup',
          choices: [
            'Em média 2 Produtos (1 a cada 6 meses)',
            'Em média 6 Produtos (1 a cada 2 meses)',
            'Em média 12 Produtos (1 por mês)',
            '+12 Produtos por ano (+ de 1 por mês)'
          ]
        }
      ]
    },
    {
      name: 'ApproximateValue',
      elements: [
        {
          name: 'ApproximateValue',
          title: 'Qual o valor aproximado das suas compras? 💵',
          description:
            'Descubra quanto você poderia estar economizando. Qual a média dos valores dos produtos que você compra?',
          type: 'radiogroup',
          visibleIf: '{PurchaseExperience} == true',
          choices: [
            'R$100 a R$250',
            'R$300 a R$900',
            'R$1000 a R$2500',
            'R$2500 a R$5000'
          ]
        }
      ]
    },
    {
      name: 'Comission',
      elements: [
        {
          name: 'Comission',
          title: 'Você sabia que pode ganhar de 2% a 15% de comissão das compras que você ou seus amigos fazem pela internet? 💰',
          type: 'radiogroup',
          choices: [{ value: true, text: 'Sim, já sei como ganhar comissão! ✅' }, { value: false, text: 'Não sabia que era possível. 😱' }]
        }
      ]
    },
    {
      name: 'ComissionImage',
      elements: [
        {
          name: 'ComissionImage',
          type: 'html',
          html: `
            <div class="sd-image-grid text-center flex flex-col gap-10">
              <h1>Além de ganhar comissões sobre suas compras, você poderia fazer uma renda <strong class="font-bold text-primary">extra</strong>.</h1>
              <div id="carousel" class="flex gap-5 sm:flex-row flex-col items-center sm:justify-between mt-8">
                <div class="max-w-[70%] sm:max-w-[50%] items-center sm:justify-between flex flex-col gap-5">
                  <h2>Registro de mais de 32 MIL reais em comissões na Amazon:</h2>
                  <img src="/comission-1.jpeg" key="comission-1.jpeg" alt="Comissões" />
                </div>
                <div class="max-w-[70%] sm:max-w-[50%] items-center sm:justify-between flex flex-col gap-5">
                  <h2>Compre produtos para você e ganhe comissões:</h2>
                  <img src="/comission-8.jpeg" key="comission-8.jpeg" alt="Comissões" />
                </div>
                <div class="max-w-[70%] sm:max-w-[50%] items-center sm:justify-between flex flex-col gap-5">
                  <h2>Veja quanto você pode ganhar indicando promoções para outras pessoas:</h2>
                  <img src="/comission-6.jpeg" key="comission-6.jpeg" alt="Comissões" />
                </div>
              </div>
            </div>
          `
        }
      ]
    },
    {
      name: 'Name',
      elements: [
        {
          name: 'Name',
          type: 'text',
          title: 'Qual seu nome?',
          placeholder: 'Nome'
        }
      ]
    },
    {
      name: 'loading',
      navigationButtonsVisibility: 'hide',
      elements: [
        {
          type: 'html',
          html: `
            <div class="mx-auto my-6 flex flex-col gap-14 items-center mb-12">
              <h2 class="text-xl text-center font-bold text-secondary mb-5">{customTitleEstamos}calculando quanto você poderia estar economizando e ganhando de comissão</h2>
              <div class="loader animate-spin"></div>
            </div>
          `
        }
      ]
    },
    {
      name: 'AdPurchases',
      elements: [
        {
          name: 'AdPurchases',
          title: '{customTitleName}já comprou produtos de anúncio ou patrocinado? 😱',
          description:
            'Um produto de anúncio é aquele que é promovido ou patrocinado por meio de anúncios pagos em plataformas online, como o Google, redes sociais ou sites de compras',
          type: 'radiogroup',
          choices: [
            {
              value: true,
              text: 'Sim, já comprei produtos de anúncio.'
            },
            {
              value: false,
              text: 'Não, prefiro evitar produtos de anúncios'
            }
          ]
        }
      ]
    },
    {
      name: 'AdPurchasesImage',
      visibleIf: '{AdPurchases} == true',
      elements: [
        {
          name: 'AdPurchasesImage',
          type: 'html',
          html: `
            <div class="sd-image-grid text-center flex flex-col gap-10">
              <h1 class="mb-5">Veja a diferença entre comprar produtos de anúncio e utilizar o método <strong class="font-bold text-primary">Compras Inteligentes!</strong></h1>
              <div id="carousel" class="flex gap-5 sm:flex-row flex-col">
                <div>
                  <h2 class="mb-2">Anúncio R$225,90 ❌</h2>
                  <img src="/step-6.jpeg" key="step-6.jpeg" alt="Relogio" />
                </div>
                <div>
                  <h2 class="mb-2"><strong class="font-bold text-primary">COMPRAS INTELIGENTES</strong> R$ 90,93 ✅</h2>
                  <img src="/step-6-2.jpeg" key="step-6-2.jpeg" alt="Relogio" />
                </div>
              </div>
            </div>
          `
        }
      ]
    },
    {
      name: 'GoogleReference',
      elements: [
        {
          name: 'GoogleReference',
          title: 'Você utiliza o Google como referência de preços?',
          description:
            'Descubra como aproveitar o Google para comparar preços e encontre as melhores ofertas disponíveis!',
          type: 'radiogroup',
          choices: [{ value: true, text: 'Sim, utilizo o Google! 👀' }, { value: false, text: 'Não, procuro em outras fontes. 🔎' }]
        }
      ]
    },
    {
      name: 'GoogleReferenceImage',
      elements: [
        {
          name: 'GoogleReferenceImage',
          type: 'html',
          html: `
            <div class="sd-image-grid text-center flex flex-col gap-10">
              <div id="carousel" class="flex gap-5 sm:flex-row flex-col">
                <div>
                  <h2>Na maioria das vezes o google <strong class="font-bold text-primary">NÃO</strong> mostra para você os melhores preços 🫤</h2>
                  <img src="/apple-watch-google.jpg" key="apple-watch-store.jpg" alt="Apple Watch" />
                </div>
                <div>
                  <h2>Utilizando o método <strong class="font-bold text-primary">COMPRAS INTELIGENTES</strong>, você economiza + de <strong class="font-bold text-primary">R$1.000,00</strong> ✅</h2>
                  <img src="/apple-watch-store.jpg" key="apple-watch-google.jpg" alt="Apple Watch" />
                </div>
              </div>
            </div>
          `
        }
      ]
    },
    {
      name: 'CheapestProducts',
      elements: [
        {
          name: 'CheapestProducts',
          type: 'html',
          html: `
            <div class="sd-image-grid text-center flex flex-col gap-10">
              <h1>Você <strong class="font-bold text-primary">vai</strong> aprender a comprar produtos <strong class="font-bold text-primary">MUITO MAIS BARATO</strong> e ainda ganhar comissões das lojas!</h1>
              <div id="carousel" class="flex gap-5 sm:flex-row flex-col items-center sm:justify-between mt-8">
                <div class="max-w-[70%] sm:max-w-[50%] min-w-[48%] justify-end flex flex-col gap-5">
                  <h2>Blazer na loja oficial da <br/>Zara custando <strong class="font-bold text-primary">R$499</strong>:</h2>
                  <img src="/step-8.jpeg" key="step-8.jpeg" alt="Blazer Zara" />
                </div>
                <div class="max-w-[70%] sm:max-w-[50%] items-center sm:justify-between flex flex-col gap-5">
                  <h2>Mesmo blazer da Zara... utilizando método <strong class="font-bold text-primary">COMPRAS INTELIGENTES</strong> você pagaria apenas <strong class="font-bold text-primary">R$162</strong>:</h2>
                  <img src="/step-8-2.jpeg" key="step-8-2.jpeg" alt="Blazer Zara" />
                </div>
              </div>
            </div>
          `
        }
      ]
    }
  ],
  calculatedValues: [{
    name: 'customTitleName',
    expression: "iif({Name} != null, {Name} + ', você ', 'Você ')"
  },
  {
    name: 'customTitleEstamos',
    expression: "iif({Name} != null, {Name} + ', estamos ', 'Estamos ')"
  }]
}

const keys = surveyJson.pages.reduce((acc: Array<any>, page: any) => {
  const elementNames = page.elements.map((element: any) => element.name)
  return [...acc, ...elementNames]
}, [])

export { links, testimonials, benefits, surveyJson, keys }
