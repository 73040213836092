import { useCallback, useContext } from 'react'
import { MdLightbulbOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import Container from '../Container'

export function NavBar ({ title, subTitle, rootUrl = '/' }: any) {
  const { isAuthenticated, user } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(rootUrl, {
      state: {
        restartSurvey: true
      }
    })
  }, [])

  return (
    <nav className={`bg-transparent py-5 h-[${isAuthenticated ? '64px' : 'auto'}] text-primary sticky lg:relative w-full pb-3`}>
      <Container>
        <div className="container mx-auto flex items-center justify-between max-w-2xl">
          <button className="flex gap-2 items-center justify-between w-full font-bold text-lg" onClick={handleClick}>
            <span className='flex gap-2 items-center'>
              <MdLightbulbOutline />
              {title}
            </span>
            <small className='font-light hidden md:block'>
              {subTitle}
            </small>
          </button>
        </div>
      </Container>
    </nav>
  )
}
