import { Routes, Route } from 'react-router-dom'

import { ComprarComDesconto } from '../pages/ComprarComDesconto'
import { ComprarComDescontoVideo } from '../pages/ComprarComDescontoVideo'
import { PublicRoute } from './PublicRoute'

export const RouteList = () => (
  <Routes>
    <Route
      path="/compras-inteligentes/video"
      element={
        <PublicRoute>
          <ComprarComDescontoVideo />
        </PublicRoute>
      }
    />

    <Route
      path="/compras-inteligentes/quiz"
      element={
        <PublicRoute>
          <ComprarComDesconto />
        </PublicRoute>
      }
    />

    <Route
      path="/"
      element={
        <PublicRoute>
          <ComprarComDesconto />
        </PublicRoute>
      }
    />

    <Route path="*"
      element={
        <div className="flex w-screen h-screen flex-col gap-4 sm:gap-10 items-center justify-center pb-[115px] sm:pb-0">
          <img src="/logo.png" className="w-[50px]"/>
          <h1 className="text-[30px]">Página não encontrada.</h1>
          {/* <Link to="/login" className="text-primary font-light underline text-lg">Voltar para </Link> */}
        </div>
      }
    />
  </Routes>
)
