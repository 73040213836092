declare const gtag: any

export const trackSocialLinks = (eventCategory: string, eventLabel: string, eventName = 'click', eventValue?: string) => {
  const params = {
    event_category: eventCategory,
    event_label: eventLabel,
    event_value: eventValue,
    transport_type: 'beacon'
  }

  const isLocalhost = window.location.hostname === 'localhost'
  if (isLocalhost) {
    console.log('Tracking is disabled on localhost.', eventName, params)
    return
  }

  try {
    const _gtag = gtag || (window as any).gtag || undefined
    console.log(_gtag, '_gtag')

    if (_gtag) {
      _gtag('event', eventName, params)
    } else {
      console.log('No gtag object found.')
    }
  } catch (e) {
    console.log('No gtag object found.', e)
  }
}
