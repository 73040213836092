import { AxiosError } from 'axios'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { api } from '../../services/api'
import { setAuthorizationHeader } from '../../services/interceptors'
import {
  createTokenCookies,
  getToken,
  removeTokenCookies
} from '../../utils/tokenCookies'

interface User {
  email: string;
  username?: string;
  permissions: string[];
  roles: string[];
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  signIn: (credentials: SignInCredentials) => Promise<boolean | AxiosError>;
  signOut: () => void;
  user: User;
  isAuthenticated: boolean;
  isAdmin: boolean;
  loadingUserData: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider ({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>()
  const [loadingUserData, setLoadingUserData] = useState(true)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const token = getToken()
  const isAuthenticated = Boolean(token)
  const userData = user as User

  async function signIn ({ email, password }: SignInCredentials): Promise<boolean | AxiosError> {
    try {
      const response = await api.post('/auth/signin', { email, password })
      const { username = '', accessToken: token, refreshToken, permissions, roles } = response.data

      createTokenCookies(token, refreshToken)
      setUser({ email, permissions, roles, username })
      setAuthorizationHeader(api.defaults, token)

      return true
    } catch (error) {
      const err = error as AxiosError
      return err
    }
  }

  function signOut (pathname = '/login') {
    removeTokenCookies()
    setUser(null)
    setLoadingUserData(false)
    navigate(pathname)
  }

  useEffect(() => {
    if (!token) signOut(pathname)
  }, [pathname, token])

  useEffect(() => {
    const token = getToken()

    async function getUserData () {
      setLoadingUserData(true)

      try {
        setAuthorizationHeader(api.defaults, token)
        const response = await api.get('/user/me')

        if (response?.data) {
          const { username, email, permissions, roles } = response.data
          setUser({ email, permissions, roles, username })
        }
      } catch (error) {
        signOut()
      }

      setLoadingUserData(false)
    }

    if (token) {
      setAuthorizationHeader(api.defaults, token)
      getUserData()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        loadingUserData,
        signIn,
        signOut,
        isAdmin: userData?.roles?.includes('ROLE_ADMIN')
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
