import { Survey } from '../../components/Survey'
import './styles.css'
import { benefits, keys, surveyJson, testimonials } from './utils'

export function ComprarComDesconto () {
  return <Survey
    json={surveyJson}
    title='Comprar com desconto'
    testimonials={testimonials}
    benefits={benefits}
    keys={keys}
    rootUrl='/compras-inteligentes/quiz'
  />
}
