import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'

import './assets/main.css'
import App from './App'

const tagManagerArgs = {
  gtmId: 'GTM-MWJNN4LJ'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
