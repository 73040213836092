import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './context/AuthContext'
import { RouteList } from './routes'

const App = () => (
  <div className="main w-screen h-auto sm:h-screen flex flex-col">
    <BrowserRouter>
      <AuthProvider>
        <RouteList />
      </AuthProvider>
    </BrowserRouter>
  </div>
)

export default App
