import { ReactSurveyElement } from 'survey-react-ui'

export default class PercentageProgressBar extends ReactSurveyElement {
  constructor (props: any) {
    super(props)
    this.state = {
      progressValue: 0
    }
  }

  progressInterval: any;

  componentDidMount () {
    this.startProgressIncrement()
  }

  componentWillUnmount () {
    this.clearProgressIncrement()
  }

  componentDidUpdate (prevProps: any, prevState: any): void {
    if (prevState.progressValue < this.props.model.progressValue) {
      this.startProgressIncrement()
    } else {
      this.startProgressDecrement()
    }
  }

  startProgressIncrement () {
    this.clearProgressIncrement() // Clear any existing interval
    this.progressInterval = setInterval(() => {
      const { progressValue } = this.state
      if (progressValue < this.props.model.progressValue) {
        this.setState((prevState: any) => ({
          progressValue: prevState.progressValue + 1 === 100 ? 99 : prevState.progressValue + 1
        }))
      } else {
        this.clearProgressIncrement() // Stop the interval when reaching the desired value
      }
    }, 50) // Adjust the interval duration as per your desired increment speed
  }

  startProgressDecrement () {
    this.clearProgressIncrement() // Clear any existing interval
    this.progressInterval = setInterval(() => {
      const { progressValue } = this.state
      if (progressValue > this.props.model.progressValue) {
        this.setState((prevState: any) => ({
          progressValue: prevState.progressValue - 1
        }))
      } else {
        this.clearProgressIncrement() // Stop the interval when reaching the desired value
      }
    }, 50) // Adjust the interval duration as per your desired increment speed
  }

  clearProgressIncrement () {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
      this.progressInterval = null
    }
  }

  render () {
    const { progressValue } = this.state

    return (
      <>
        {progressValue !== 0
          ? (
            <div className="sv-progressbar-percentage">
              <div
                className="sv-progressbar-percentage__value"
                style={{ width: progressValue + '%' }}
              >
                <span>{`${progressValue}%`}</span>
              </div>
            </div>
          )
          : null}
      </>
    )
  }
}
