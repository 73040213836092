import { api } from './api'

export const saveLead = async (lead : any | null) => {
  try {
    const response = await api.post('/leads/save', lead)

    if (!response.data) {
      throw new Error('Error')
    }
    return response.data
  } catch (err) {
    console.log(err, 'Error getting lead')
  }
}
