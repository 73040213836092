import { Splide, SplideSlide } from '@splidejs/react-splide'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { BiCheckCircle, BiSolidStar } from 'react-icons/bi'
import { MdArrowDownward } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { Model, Serializer } from 'survey-core'
import { ReactElementFactory, Survey as SurveyReact } from 'survey-react-ui'

import 'survey-core/survey.i18n'
import { links } from '../../pages/ComprarComDesconto/utils'
import { saveLead } from '../../services/leads.service'
import { trackSocialLinks } from '../../utils/analytics'
import Container from '../Container'
import { NavBar } from '../NavBar'
import PercentageProgressBar from '../PercentageBar'

interface ISurvey {
  json: {pages: Array<any>};
  title?: string;
  testimonials: Array<{
    author: string;
    quote: string;
    stars: number;
    image: string
  }>;
  benefits?: any;
  keys: Array<any>;
  rootUrl?: string;
}

function saveSurveyResults (json: any) {
  try {
    saveLead(json)
  } catch {
    console.log('Error saving lead')
  }
}

const regexPhoneNumber = /^\(\d{2}\)\s?\d{4,5}-?\d{4}$/
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

Serializer.addProperty('survey', 'progressTitle')

ReactElementFactory.Instance.registerElement('sv-progressbar-percentage', props => {
  return React.createElement(PercentageProgressBar, props)
})

const _SurveyComp = ({ json, setCurrentStep, setAnswers, setHasFinishedSurvey }: any) => {
  const survey = new Model(json)

  const surveyComplete = useCallback((sender: any) => {
    // saveSurveyResults(sender.data)
    setAnswers(sender.data)
    setHasFinishedSurvey(true)
  }, [])

  const saveState = () => {
    const res = { currentPageNo: survey.currentPageNo, data: survey.data }
    window.localStorage.setItem('SURVEY_DATA', JSON.stringify(res))
  }

  const changeCallback = useCallback((sender: Model) => {
    if (sender?.currentPage?.propertyHash?.name === 'loading') {
      setTimeout(() => {
        survey.nextPage()
      }, 3000)
    }
    setCurrentStep(sender.currentPageNo)
    trackSocialLinks('question', sender?.currentPage?.propertyHash?.name, 'page_view')

    // save state
    saveState()
  }, [])

  const changeValueCallback = (sender: Model, options: any) => {
    if (options.name !== 'Name') {
      trackSocialLinks('question', options.name, 'click', options.value)
    }
  }

  survey.locale = 'pt-br'
  survey.onComplete.add(surveyComplete)
  survey.onCurrentPageChanged.add(changeCallback)
  survey.onValueChanged.add(changeValueCallback)
  survey.completeText = 'Finalizar'

  survey.onAfterRenderQuestion.add(function (survey, options) {
    console.log(survey?.isShowStartingPage)
    if (!survey?.isShowStartingPage && survey?.isFirstPage) {
      console.log('entrou')
      setTimeout(() => {
        survey.addLayoutElement({
          id: 'progressbar-percentage',
          component: 'sv-progressbar-percentage',
          container: 'header',
          data: survey
        })
      }, 100)
    }

    const carouselDiv = options.htmlElement.querySelector('#carousel')
    if (carouselDiv) {
      const itemsFindInTheCarousel = Array.from(carouselDiv.querySelectorAll('div'))

      const carouselContent = (
        <div id="carousel" className='max-w-[100%] mb-[50px] sm:mb-0 pb-5 mt-4'>
          <Splide
            aria-label="My Favorite Images"
            options={{
              perPage: window.innerWidth > 768 ? 2 : 1,
              width: '100%',
              interval: 5000,
              arrows: true
            }}
          >
            {itemsFindInTheCarousel.map((item: HTMLElement, index: number) => (
              <SplideSlide key={index}>
                <div className='w-[100%] px-5 max-w-[100%] bg-white rounded-md relative flex items-center gap-3 flex-col'
                  dangerouslySetInnerHTML={{ __html: item.innerHTML }}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )

      const container = document.createElement('div')
      container.style.opacity = '0'
      container.style.transition = 'opacity .2s'
      render(carouselContent, container)

      if (carouselDiv) {
        carouselDiv.parentNode?.replaceChild(container, carouselDiv)
        try {
          setTimeout(() => {
            container.style.opacity = '1'
          }, 300)
        } catch {
          console.log('error')
        }
      }
    }
  })

  // Here should be the code to load the data from your database
  const persistedSurvey = window.localStorage.getItem('SURVEY_DATA') || ''

  if (persistedSurvey) {
    const _persistedSurvey = JSON.parse(persistedSurvey)
    if (_persistedSurvey?.data && Object.keys(_persistedSurvey.data).length > 0) {
      survey.data = _persistedSurvey.data

      if (_persistedSurvey?.currentPageNo) {
        survey.currentPageNo = _persistedSurvey.currentPageNo
      }
    }
  }

  return <SurveyReact model={survey} />
}

const SurveyComp = memo(_SurveyComp)

export function Survey ({ json, testimonials, keys, rootUrl }: ISurvey) {
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [hasFinishedSurvey, setHasFinishedSurvey] = useState(false)
  const [hasSavedLead, setHasSavedLead] = useState<boolean | string>(false)
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [answers, setAnswers] = useState({} as any)
  const location = useLocation()
  const [emailError, setEmailError] = useState(false)

  useEffect(() => {
    // Check if replace is true
    const isRestartSurvey = location.state && location.state.restartSurvey
    if (isRestartSurvey) {
      // Handle the replace logic here
      setAnswers({} as any)
      setHasFinishedSurvey(false)
      setHasSavedLead(false)
      keys.forEach(key => {
        window.localStorage.removeItem(key)
      })
      window.localStorage.removeItem('hasFinishedSurvey')
      window.localStorage.removeItem('SURVEY_DATA')
    }
  }, [location])

  useEffect(() => {
    try {
      if (hasFinishedSurvey === true && Object.keys(answers).length > 0 && !window.localStorage.getItem('hasFinishedSurvey')) {
        Object.keys(answers).forEach(key => {
          if (answers[key]) {
            window.localStorage.setItem(key, answers[key])
          }
        })

        if (answers.Name) {
          window.localStorage.setItem('hasFinishedSurvey', 'true')
          const temp = {} as any

          if (answers.ProductsBought) {
            temp.priceTotal = ((answers.ProductsBought?.includes('+12') ? 14 : parseInt(answers.ProductsBought.replace('Em média ', '').slice(0, 2))) * parseInt(answers.ApproximateValue.slice(2, 7)))
            temp.priceFormatted = temp.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            temp.priceSavingsMin = (temp.priceTotal * 0.4).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            temp.priceSavingsMax = (temp.priceTotal * 0.8).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            temp.priceComissionMin = (temp.priceTotal * 0.1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            temp.priceComissionMax = (temp.priceTotal * 0.2).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }

          setAnswers((prevValue: any) => ({ ...prevValue, ...temp }))
        }
      }
    } catch (e) {
      console.log(e, 'not able to save answers')
    }
  }, [hasFinishedSurvey])

  useEffect(() => {
    setIsLoading(false)
    try {
      if (window.localStorage.getItem('hasFinishedSurvey') === 'true' && window.localStorage.getItem('Name')) {
        const temp = {} as any
        keys.forEach(key => {
          temp[key] = window.localStorage.getItem(key)
        })

        if (temp.ProductsBought) {
          temp.priceTotal = ((temp.ProductsBought?.includes('+12') ? 14 : parseInt(temp.ProductsBought.replace('Em média ', '').slice(0, 2))) * parseInt(temp.ApproximateValue.slice(2, 7)))
          temp.priceFormatted = temp.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          temp.priceSavingsMin = (temp.priceTotal * 0.4).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          temp.priceSavingsMax = (temp.priceTotal * 0.8).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          temp.priceComissionMin = (temp.priceTotal * 0.1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          temp.priceComissionMax = (temp.priceTotal * 0.2).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        setAnswers(temp)
        setHasFinishedSurvey(true)
      }
    } catch (error) {
      console.log(error, 'not able to get hasFinishedSurvey')
    }
  }, [])

  useEffect(() => {
    try {
      if (!hasFinishedSurvey) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' }) // Smooth scroll to the top when the component mounts
        }, 100)
      }
    } catch (e) {
      console.log(e, 'Error scrolling to top')
    }
  }, [currentStep, hasFinishedSurvey]) // Trigger the effect when the currentStep changes

  const submitLead = async (forcePhone?: string) => {
    try {
      await saveSurveyResults({
        ...answers,
        Telefone: forcePhone || phone,
        email,
        ProductsBought: answers.ProductsBought ? answers?.ProductsBought?.replace(/\+/g, 'mais de ') : ''
      })

      console.log('lead save succesfully')

      setHasSavedLead(email)
    } catch (e) {
      console.log(e, 'Error saving lead')
    }
  }

  const handleSubmit = async (e: any) => {
    setEmailError(false)

    if (!regexEmail.test(email)) {
      e.preventDefault()
      setEmailError(true)
      return
    }

    try {
      setIsLoading(true)
      if (!hasSavedLead) {
        submitLead()
      }

      trackSocialLinks('submit', 'submit button')

      setTimeout(() => {
        // window.location.href = links.checkout
        setIsLoading(false)
      }, 500)
    } catch (e) {
      console.log(e, 'not able to save answers')
      setIsLoading(false)
    }
  }

  const handleChangePhone = (e: any) => {
    const { value: _phone } = e.target
    setPhone(_phone)

    if (regexPhoneNumber.test(_phone) && (!hasSavedLead || (hasSavedLead && hasSavedLead !== _phone))) {
      submitLead(_phone)
    }
  }

  const handleChangeEmail = (e: any) => {
    const { value: _email } = e.target
    setEmail(_email)
    setEmailError(false)
  }

  const onBlurEmail = () => {
    if (regexEmail.test(email) && (!hasSavedLead || (hasSavedLead && hasSavedLead !== email))) {
      try {
        submitLead(email)
        trackSocialLinks('question', 'Email', 'click', email)
      } catch (e) {
        console.log(e, 'not able to save answers')
      }
    }
  }

  const videoContent = '<div id="vid_64bad3310373240009921fdf" style="position:relative;width:100%;padding: 56.25% 0 0;"><img id="thumb_64bad3310373240009921fdf" src="https://images.converteai.net/c275c39f-cf9d-4c61-ad9d-c4cca26f4b21/players/64bad3310373240009921fdf/thumbnail.jpg" style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;display:block;"><div id="backdrop_64bad3310373240009921fdf" style="position:absolute;top:0;width:100%;height:100%;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);"></div></div>'

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'scr_64bad3310373240009921fdf'
    script.async = true
    script.innerHTML =
      'var s=document.createElement("script");s.src="https://scripts.converteai.net/c275c39f-cf9d-4c61-ad9d-c4cca26f4b21/players/64bad3310373240009921fdf/player.js",s.async=!0,document.head.appendChild(s);'

    if (hasFinishedSurvey) {
      document.head.appendChild(script)

      setTimeout(() => {
        setIsMounted(true)
      }, 1000)
    }

    // return () => {
    //   document.head.removeChild(script)
    // }
  }, [hasFinishedSurvey])

  return (
    <div className={`flex w-screen flex-auto flex-col justify-start items-start ${!hasFinishedSurvey ? 'h-[calc(100%-80px)]' : ' '}`}>
      <NavBar title='Compras Inteligentes' rootUrl={rootUrl} />
      <Container>
        {hasFinishedSurvey
          ? (
            <div className="max-w-2xl mx-auto w-full mt-2">
              <div className={`max-w-[1200px] w-full mx-auto relative transition-all transform-gpu translate-y-[10px] ${isMounted ? 'translate-y-0' : ''} overflow-hidden rounded-md`}>
                <div dangerouslySetInnerHTML={{ __html: videoContent }} />
              </div>

              <form className='mx-[-12px] sm:mx-0 sm:rounded-lg flex flex-col gap-3 p-2 px-[10px] bg-gray-50 mt-4'>
                <h5 className="text-[15px] sm:text-lg text-secondary font-normal leading-6">
                  {answers.Name ? <><strong className='text-primary font-bold capitalize'>{answers.Name}</strong>, chegou a hora </> : 'Chegou a hora'} de parar de ser enganado(a) na internet, você está comprando produtos caros! Adquira o ebook <strong className='text-primary font-bold'>COMPRAS INTELIGENTES</strong>, aprenda a economizar até <strong className='font-bold text-primary'>80%</strong> e ainda a ganhar <strong className='font-bold text-primary'>comissões</strong> das lojas, <strong>sem precisar utilizar MILHAS AÉREAS</strong>!
                </h5>
                <MdArrowDownward className='text-primary m-auto w-[30px] h-[30px] animate-bounce mb-[-10px]'/>
                <input
                  value={email}
                  className={`w-full text-[16px] p-4 sm:p-4 font-light border-gray-300 rounded-md focus:border-primary border-2 outline-none shadow-sm ${emailError ? 'border-red-500' : ''}`}
                  onChange={handleChangeEmail}
                  onBlur={onBlurEmail}
                  placeholder='Seu email'
                  type='email'
                  name="email"
                />
                {emailError
                  ? (
                    <p className='text-xs font-medium text-red-500'>Por favor, preencha seu email</p>
                  )
                  : null}
                <a href={links.checkout} target="_blank" onClick={handleSubmit} className={`p-5 w-full bg-primary text-center rounded-md text-white font-bold hover:bg-[#f4460f] transition ${(isLoading) ? 'opacity-80 pointer-events-none cursor-not-allowed' : ''}`} rel="noreferrer">
                  {isLoading ? 'Carregando...' : 'QUERO APRENDER AGORA! 🚀'}
                </a>
              </form>
              <h3 className='font-bold text-lg sm:text-[22px] text-secondary mt-2 p'>
                {answers.Name ? <><strong className='text-primary'>{answers.Name}</strong>, com</> : 'Com'} base nas suas respostas:
              </h3>
              <div className='flex flex-col gap-1 text-gray-700 text-[15px] sm:text-[16px]'>
                {answers.AdPurchases === 'true' || answers.AdPurchases === true
                  ? (
                    <p className=''>Você compra produtos de anúncio, e paga até 2x <strong className='font-bold text-red-800'>MAIS CARO</strong>! 🤦‍♂️</p>
                  )
                  : null}
                {answers.GoogleReference === 'true' || answers.GoogleReference === true
                  ? (
                    <p className=''>Você utiliza o google como fonte de pesquisa, está sendo manipulado(a) e comprando produtos <strong className='font-bold text-red-800'>MAIS CAROS</strong>! 🤦‍♀️</p>
                  )
                  : null}
                {(answers.PurchaseExperience === 'true' || answers.PurchaseExperience === true) && answers.ProductsBought & answers.priceComissionMin
                  ? (
                    <>
                      <h4 className='font-light text-[16px] mt-1 text-secondary bg-gray-100 p-2 sm:p-4 rounded-md'>Você <strong className='font-bold text-primary inline-block'>vai aprender</strong> a ganhar de <strong className='font-bold text-primary inline-block'>{answers.priceComissionMin}</strong> a <strong className='font-bold text-primary inline-block-block'>{answers.priceComissionMax}</strong> em comissões sobre suas compras! 💵</h4>
                      <h4 className='font-light text-[16px] mt-1 text-secondary bg-gray-100 p-2 sm:p-4 rounded-md'>Você poderia estar economizando de <strong className='font-bold text-primary inline-block'>{answers.priceSavingsMin}</strong> até <strong className='font-bold text-primary inline-block'>{answers.priceSavingsMax}</strong>! 😱😱😱</h4>
                    </>
                  )
                  : (
                    <>
                      <h4 className='font-light leading-5 text-[16px] mt-1 text-secondary bg-gray-100 p-2 sm:p-4 rounded-md'>Você <strong className='font-bold text-primary inline-block'>vai aprender</strong> a ganhar de <strong className='font-bold text-primary inline-block'>R$ 200,00</strong> a <strong className='font-bold text-primary inline-block'>R$ 10.000,00</strong> em comissões sobre suas compras! 💵</h4>
                      <h4 className='font-light leading-5 text-[16px] mt-1 text-secondary bg-gray-100 p-2 sm:p-4 rounded-md'>Você poderia estar economizando de <strong className='font-bold text-primary inline-block'>R$ 100,00</strong> até <strong className='font-bold text-primary inline-block'>R$ 4.000,00</strong> em todas as suas compras! 😱😱😱</h4>
                    </>
                  )}
              </div>
            </div>
          )
          : (
            <SurveyComp
              json={json}
              setCurrentStep={setCurrentStep}
              setAnswers={setAnswers}
              setHasFinishedSurvey={setHasFinishedSurvey}
            />
          )}

        {/* Testimonials */}
        <div className={`testimonials w-full mx-auto items-start max-w-2xl flex-col gap-4 mt-2 ${!hasFinishedSurvey ? 'border-t mt-[50px] mb-[0px] pb-[130px] pt-8 mx-auto sm:px-2 w-auto sm:w-full' : 'flex pb-8 mb-0'}`}>
          <h3 className='font-bold text-md sm:text-xl text-secondary'>Relatos de quem começou a <strong className='text-primary font-bold uppercase'>economizar</strong></h3>
          <Splide aria-label="My Favorite Images" options={{
            autoplay: true,
            type: 'loop',
            perPage: 1,
            width: '100%',
            interval: 4000,
            arrows: false,
            pagination: false
          }}>
            {testimonials.map((testimonial: any, index: number) => (
              <SplideSlide key={index}>
                <div className='gap-5 my-3 mb-7 px-[20px] mx-2 py-8 shadow-lg bg-white rounded-md relative flex items-start flex-col'>
                  <div className="flex gap-4 items-center">
                    <div className='image-container'>
                      <div
                        className='testimonial-image w-[80px] h-[80px] rounded-full'
                        style={{ backgroundImage: `url(${testimonial.image})`, backgroundSize: 'cover' }}
                      ></div>
                    </div>
                    <div className="flex flex-col">
                      <h4 className='font-bold flex gap-2 items-start flex-col mb-2'>{testimonial.author}<span className='text-primary text-sm font-normal bg-[#ff4a111a] items-center px-2 py-1 rounded-sm flex gap-1'><BiCheckCircle/> Usuário verificado</span></h4>
                      <p className='flex gap-1 fill-blue text-sm'>{Array.from({ length: testimonial.stars }, () => 1).map((_, index) => <BiSolidStar key={index} className='fill-yellow-400' />)}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className='font-light text-sm mt-1 text-secondary'>{testimonial.quote}</p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

      </Container>
    </div>
  )
}
