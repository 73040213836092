import { CSSProperties, useEffect, useState } from 'react'
import './styles.css'

interface Styles {
  backgroundImage: CSSProperties;
  video: CSSProperties;
  thumbnail: CSSProperties;
  backdrop: CSSProperties;
}

const styles: Styles = {
  backgroundImage: {
    backgroundImage: 'url(/background-video.jpeg)'
  },
  video: {
    position: 'relative',
    width: '100%',
    padding: '56.25% 0 0'
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    WebkitBackdropFilter: 'blur(5px)', // Using Webkit vendor prefix for TypeScript compatibility
    backdropFilter: 'blur(5px)'
  }
}

export function ComprarComDescontoVideo () {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    // Dynamically insert the script before mounting
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'scr_6510ccfe2138c20008efebf7'
    script.async = true
    script.innerHTML = `
    var s = document.createElement("script");
    (s.src =
      "https://scripts.converteai.net/c275c39f-cf9d-4c61-ad9d-c4cca26f4b21/players/6510ccfe2138c20008efebf7/player.js"),
      (s.async = !0),
      document.head.appendChild(s); 
    `
    document.head.appendChild(script)

    setTimeout(() => {
      setIsMounted(true)
    }, 400)
  }, [])

  const videoContent = '<div id="vid_6510ccfe2138c20008efebf7" style="position: relative; width: 100%; padding: 56.25% 0 0"><img id="thumb_6510ccfe2138c20008efebf7" src="https://images.converteai.net/c275c39f-cf9d-4c61-ad9d-c4cca26f4b21/players/6510ccfe2138c20008efebf7/thumbnail.jpg" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;display: block;"/><div id="backdrop_6510ccfe2138c20008efebf7" style="position: absolute;top: 0;width: 100%;height: 100%;-webkit-backdrop-filter: blur(5px);backdrop-filter: blur(5px);"></div></div>'
  return (
    <div
      className={'h-screen w-screen flex flex-col items-center bg-cover bg-no-repeat bg-center opacity-0 overflow-auto p-5'}
      style={{ ...styles.backgroundImage, opacity: isMounted ? 1 : 0 }}
    >
      <div className="flex flex-col w-[100%] container mx-auto max-w-[1300px] pt-10 gap-5 sm:pt-5 h-full">
        <h1 className={`text-2xl sm:text-4xl font-bold mx-auto text-white relative text-center items-center p-3 justify-center flex transition-all transform-gpu translate-y-[10px] ${isMounted ? 'translate-y-0' : ''}`}>
          <span className='w-full opacity-[0.9] absolute h-full block z-10 bg-[#450f57] rounded-[50px] p-[20px]' style={{ filter: 'blur(12px)' }}></span>
          <span className='z-20 relative'>Compre produtos com até <strong className='text-primary'>80%</strong> de desconto e ganhe <strong className='text-primary'>RENDA EXTRA</strong>!</span>
        </h1>

        <div className={`max-w-[1200px] w-full mx-auto relative transition-all transform-gpu translate-y-[10px] ${isMounted ? 'translate-y-0' : ''}`}>
          <div dangerouslySetInnerHTML={{ __html: videoContent }} />
        </div>
      </div>
    </div>
  )
}
