import { ReactNode } from 'react'

interface Props {
  children: ReactNode;
}

export default function Container ({ children }: Props) {
  return (
    <div className="flex flex-col w-[100%] container mx-auto max-w-[1300px] gap-4 sm:gap-10 px-3 md:px-0">
      {children}
    </div>
  )
}
